import React, {useState} from 'react';
import {graphql, useStaticQuery} from "gatsby";
import HeroImage from "../components/global/heroImage";
import InnerLayout from "../components/global/innerLayout"
import PageTitle from "../components/global/pageTitle";
import "./manage-newsletters.scss"
import axios from "axios/index";
import useSSR from "use-ssr";

const Page = () => {

    const { isBrowser } = useSSR()

    let formSubmitted = false
    let listSelected = false

    const [isFormSubmitted, setFormSubmitted] = useState(false)
    const [isListSelected, setListSelected] = useState(false)
    const [fetchedSubscriptions, setFetchedSubscriptions] = useState(false)
    const [isUrlRecognized, setIsUrlRecognized] = useState(true)

    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "manageNewsletters") {
              ... on Craft_manageNewsletters_manageNewsletters_Entry {
                id
                title
                pageTitle
                newsletterHeroImage {
                  ... on Craft_newsletterHeroImage_image_BlockType {
                    image {
                      url
                      title
                      ... on Craft_websiteGeneral_Asset {
                        altText
                      }
                    }
                    imageLink {
                      linkUrl
                      openInNewTab
                    }
                  }
                }
                seomatic {
                  metaTitleContainer
                  metaTagContainer
                  metaLinkContainer
                  metaScriptContainer
                  metaJsonLdContainer
                  metaSiteVarsContainer
                  frontendTemplateContainer
                }
              }
            }
          }
        }
  `)

    axios.defaults.headers.post['Content-Type'] ='application/json';

    let subsriberData = {
        id: '',
        hash: '',
        lists: []
    }

    const getEmailAddress = () => {

        if (!isBrowser) return

        const params = new URLSearchParams(window.location.search)
        const encodedRecipient = params.get('recip')

        // If the recip param isn't present, redirect to newsletter signup page
        if (!encodedRecipient) {
            setIsUrlRecognized(false)
            return
        } else {
            try {
                const recipientInfo = encodedRecipient.split('|')
                const email = atob(recipientInfo[0])

                // If the email isn't an email, redirect to newsletter signup page
                if (!String(email).toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                    setIsUrlRecognized(false)
                    return
                } else {

                    subsriberData.hash = recipientInfo[1]
                    subsriberData.id = recipientInfo[2]

                    if (!subsriberData.hash || !subsriberData.id) {
                        setIsUrlRecognized(false)
                        return
                    } else {
                        getSubscriptions(subsriberData)
                        return email
                    }
                }
            } catch (e) {
                setIsUrlRecognized(false)
                return
            }
        }
    }

    const unCheck = (e) => {
        let target

        switch(e.target.value){
            case 'weekly-bulletin':
                target = 'daily-bulletin'
                break;
            case 'daily-bulletin':
                target= 'weekly-bulletin'
                break;
        }

        if (target && e.target.checked) {
            document.querySelector('input[value="' + target + '"]').checked = false
        }

        listClick(e)
    }

    const listClick = (e) => {
        if (e.target.checked) {
            document.querySelector('input[value="unsubscribe-all"]').checked = false
        }
    }

    const unSubscribeAll = (e) => {
        if (e.target.checked) {
            document.querySelectorAll('input.list').forEach(input => input.checked = false)
        }
    }

    const setCustomValidity = (e) => {
        switch(e.target.name) {
            case 'agree_to_terms':
                e.target.setCustomValidity('Please read and accept these terms to proceed')
                break;
        }
    }

    const unsetCustomValidity = (e) => {
        e.target.setCustomValidity('')
    }

    const getSubscriptions = (data) => {
        if (!fetchedSubscriptions) {
            axios.put('https://u2ko7qjlya.execute-api.us-east-1.amazonaws.com/production/manage', data)
                .then(result => {
                    if (result.data.status === 200) {

                        // Load subscription data
                        let form = document.getElementById("subscriptionForm")

                        result.data.response.forEach(list => {
                            if (!list || !list.name) return

                            switch(list.name){
                                case "daily-bulletin":
                                    form.elements.dailyBulletin.checked = true
                                    break
                                case "weekly-bulletin":
                                    form.elements.weeklyBulletin.checked = true
                                    break
                                case "inspiration-for-busy-moms":
                                    form.elements.inspirationForBusyMoms.checked = true
                                    break
                                case "inspiration-for-your-cancer-journey":
                                    form.elements.inspirationForYourCancerJourney.checked = true
                                    break
                                case "positively-pets":
                                    form.elements.positivelyPets.checked = true
                                    break
                                case "faith-and-spirituality":
                                    form.elements.faithAndSpirituality.checked = true
                                    break
                            }
                        })

                        setFetchedSubscriptions(true)

                    } else {
                        setIsUrlRecognized(false)
                        return
                    }
                })
        }
    }

    const subscribe = (e) => {
        e.preventDefault()

        formSubmitted = true
        setFormSubmitted(true)

        // Reset the lists
        subsriberData.lists = []

        if (e.target.elements.dailyBulletin.checked) {
            subsriberData.lists.push(e.target.elements.dailyBulletin.value)
        }

        if (e.target.elements.weeklyBulletin.checked) {
            subsriberData.lists.push(e.target.elements.weeklyBulletin.value)
        }

        if (e.target.elements.faithAndSpirituality.checked) {
            subsriberData.lists.push(e.target.elements.faithAndSpirituality.value)
        }

        if (e.target.elements.positivelyPets.checked) {
            subsriberData.lists.push(e.target.elements.positivelyPets.value)
        }

        if (e.target.elements.inspirationForBusyMoms.checked) {
            subsriberData.lists.push(e.target.elements.inspirationForBusyMoms.value)
        }

        if (e.target.elements.inspirationForYourCancerJourney.checked) {
            subsriberData.lists.push(e.target.elements.inspirationForYourCancerJourney.value)
        }

        if (subsriberData.lists.length === 0 && e.target.elements.unsubscribeAll.checked === false) {
            listSelected = false
            setListSelected(false)
            document.getElementById('list-selection-error').scrollIntoView()
        } else {
            listSelected = true
            setListSelected(true)
        }

        if (!listSelected) {
            return false
        } else {
            axios.post('https://u2ko7qjlya.execute-api.us-east-1.amazonaws.com/production/manage', subsriberData)
                .then(result => {
                    if (result.data.status === 200) {
                        window.location.href = '/manage-newsletters/thank-you'
                    } else {
                        window.location.href = '/free-stories-email-newsletters'
                    }
                })
        }
    }

    return (
        <InnerLayout seomatic={data.craft.entry.seomatic} entryId={data.craft.entry.id}>
            <div className="midd manage-newsletters-page">
                <PageTitle title={data.craft.entry.pageTitle}/>

                <div className="content-page">
                    <div className="container-small">
                        <div className="static-page-banner">
                            <HeroImage image={data.craft.entry.newsletterHeroImage}/>
                        </div>

                        {!isUrlRecognized &&
                            <div id="urlUnrecognizedMessage">
                                <p>It appears that you're trying to update your story email newsletter preferences, but this URL not recognized.</p>
                                <p>Please click on Manage Newsletter Preferences from the footer of a recent free story email.</p>
                                <p>If you are copying and pasting the URL, be sure to copy the entire URL (it's pretty long!) to access your email newsletter preferences.</p>
                                <p>For further assistance, please <a href="/about/contact-us">contact us</a>.</p>
                            </div>
                        }
                        {isUrlRecognized &&
                            <form id="subscriptionForm" className="form-step-block-story" onSubmit={subscribe}>
                                <div className="content-colum-row">
                                    <div className="large-text text-center">
                                        <h3>Manage Email Newsletter Settings For:</h3>
                                        <p><strong>{getEmailAddress()}</strong></p>

                                        <p>Check the boxes for the newsletters you would like to receive.<br/>Uncheck
                                            (clear) the boxes to unsubscribe from newsletters.</p>
                                    </div>
                                    <div className="step-block-story-newsletter">
                                        <div className="choose-to-story">
                                            <div className="step-form">
                                                <h4>Chicken Soup for the Soul Free Story Newsletters</h4>
                                                <span id="list-selection-error"
                                                      className={isFormSubmitted && !isListSelected ? 'error visible' : 'error'}>You must select at least 1 newsletter or select unsubscribe from all free story newsletters</span>

                                                <div className="chkFlex">
                                                    <input type="checkbox" className="list" name="dailyBulletin"
                                                           value="daily-bulletin" onClick={unCheck}/>
                                                    <p>Your <strong>Daily Inspiration:</strong> A free Chicken Soup for the Soul story in your email every day. Sample stories from our bestselling books!</p>
                                                </div>
                                                <em>or</em>
                                                <div className="chkFlex">
                                                    <input type="checkbox" className="list" name="weeklyBulletin"
                                                           value="weekly-bulletin" onClick={unCheck}/>
                                                    <p>Your <strong>Weekly Inspiration:</strong> A free Chicken Soup for the Soul story just once a week, on Wednesdays. Sample our bestselling books!</p>
                                                </div>
                                            </div>

                                            <div className="step-form">
                                                <div className="chkFlex">
                                                    <input type="checkbox" className="list" name="faithAndSpirituality"
                                                           value="faith-and-spirituality" onClick={listClick}/>
                                                    <p><strong>Faith & Spirituality:</strong> A free Chicken Soup for the Soul story in your email every Monday. Miracles, angels, messages from heaven, and more!</p>
                                                </div>
                                                <div className="chkFlex">
                                                    <input type="checkbox" className="list" name="positivelyPets"
                                                           value="positively-pets" onClick={listClick}/>
                                                    <p><strong>Positively Pets:</strong> A free Chicken Soup for the Soul story in your email every Friday. Entertainment and inspiration from our dogs, cats, and other pets.</p>
                                                </div>
                                                <div className="chkFlex">
                                                    <input type="checkbox" className="list"
                                                           name="inspirationForBusyMoms"
                                                           value="inspiration-for-busy-moms" onClick={listClick}/>
                                                    <p><strong>Inspiration for Busy Moms:</strong> A free Chicken Soup for the Soul story in your email every Tuesday. Entertainment and practical advice&mdash;moms sharing with moms.</p></div>
                                                <div className="chkFlex">
                                                    <input type="checkbox" className="list"
                                                           name="inspirationForYourCancerJourney"
                                                           value="inspiration-for-your-cancer-journey"
                                                           onClick={listClick}/>
                                                    <p><strong>Inspiration for Your Cancer Journey:</strong> A free Chicken Soup for the Soul story in your email every Thursday. Support and understanding for you and your loved ones.</p>
                                                </div>
                                            </div>

                                            <div className="step-form story-small-text-mob">
                                                <div className="chkFlex">
                                                    <input type="checkbox" name="unsubscribeAll" value="unsubscribe-all"
                                                           onClick={unSubscribeAll}/>
                                                    <p>Unsubscribe from all free story newsletters</p>
                                                </div>
                                                <p className="small-text"><strong>You can unsubscribe at any
                                                    time</strong> by clicking on the "Unsubscribe" link at the
                                                    bottom of your newsletter emails, which will be sent via Upland PostUp,
                                                    the
                                                    email service provider for our newsletters.</p>

                                                <p className="small-text"><strong>Want to update your email
                                                    address?</strong> Simply unsubscribe from newsletters from this
                                                    form (clear all boxes and submit), then visit our <a
                                                        href="/free-stories-email-newsletters">Chicken Soup for the Soul
                                                        newsletter
                                                        signup page</a> to subscribe with your new email address.</p>
                                            </div>

                                            <div className="step-form story-small-text-mob">
                                                <div className="chkFlex">
                                                    <input type="checkbox" name="agree_to_terms" required
                                                           onInvalid={setCustomValidity} onInput={unsetCustomValidity}/>
                                                    <p className="small-text">I agree with the Chicken Soup for the Soul <a
                                                        href="/privacy-policy" target="_blank" rel="noreferrer">Privacy
                                                        Statement</a> and <a href="/terms-of-use" target="_blank"
                                                                             rel="noreferrer">Terms of Use</a>, and
                                                        acknowledge that the information I provide will be transferred to
                                                        Upland PostUp for processing in accordance with <a
                                                            href="https://uplandsoftware.com/privacy/" target="_blank"
                                                            rel="noreferrer">their Privacy Policy</a>.<span
                                                            className="required">*</span></p>
                                                </div>
                                                <div className="submit-btn">
                                                    <input className="btn btn-primary" type="submit"
                                                           value="Update Preferences"/>
                                                </div>

                                                <p className="small-text"><strong>Want to manage other Chicken Soup for the
                                                    Soul mailings?</strong> <a
                                                    href="/about/faq" target="_blank" rel="noreferrer">Visit our FAQ</a> for
                                                    more info.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default Page
